import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { HOME_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

import {
  Hidden, Button, Grid, Card, CardContent, Typography,
} from '@material-ui/core';
import useStyles from './styles';

const ContactUsSuccess = ({ emailResult }) => {
  const styles = useStyles();
  const { name = '', email = '', message = '' } = emailResult;

  return (
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={styles.noMargin}>
              Your message has been sent (details below)
          </Typography>
          <Typography variant="body2" className={styles.caption}>
          {'If we need more information or can help in any way we will reply to the email address provided.'}
          </Typography>
          </Grid>
          <Grid item xs={10}>
            <Card className={styles.card}>
            <CardContent>
                <Typography variant="body2" className={styles.body1}>
                    {`from: "${name}: ${email}"`}
                </Typography>
                <Typography variant="body2" className={classNames(styles.body1, styles.noMarginBottom)}>
                    {`message: "${message}"`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={10} className={styles.noMargin}>
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              className={styles.formbutton}
              onClick={() => navigate(HOME_ROUTE)}
              aria-label="goto home page"
              fullWidth
              endIcon={
                <FontAwesomeIcon icon={faHome} className={styles.submitIcon}/>
              }
              >
             Home page
           </Button>
          </Hidden>
          <Hidden xsDown>
            <Button
              variant="contained"
              color="primary"
              className={styles.formbutton}
              onClick={() => navigate(HOME_ROUTE)}
              aria-label="goto home page"
              fullWidth
              endIcon={
                <FontAwesomeIcon icon={faHome} className={styles.submitIcon}/>
              }
              >
             Go to home page
           </Button>
          </Hidden>
        </Grid>
      </Grid>
  );
};

ContactUsSuccess.propTypes = {
  emailResult: object.isRequired,
};

export default ContactUsSuccess;
