import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  body2: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
  body1: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  caption: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: theme.spacing(0.5),
  },
  card: {
    backgroundColor: theme.invertedBackgroundColor,
    color: 'black',
  },
  noMarginBottom: {
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
  },
  noMargin: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
  },
});
